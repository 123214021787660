/* src/styles/AboutPage.css */

.about {
  font-family: "Roboto", "Helvetica", "Sans-serif";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  text-align: center;
  padding: 20px;
}

.about h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #5a5a5a;
}

.about p {
  font-size: 18px;
  max-width: 600px;
  color: #5a5a5a;
}
