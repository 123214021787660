/* src/App.css */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #d7ded9;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex: 1; /* Make the main content area grow to fill available space */
  padding-top: 80px; /* Adjust this value to match the header height */
  padding-bottom: 40px; /* Adjust this value to match the footer height */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 20px; /* Padding around the app */
  box-sizing: border-box; /* Ensure padding does not affect the container size */
}


