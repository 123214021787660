/* src/styles/Footer.css */

.footer {
  width: 100%;
  padding: 20px 0;
  text-align: center;
  background-color: #d7ded9;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  font-size: 14px;
  color: #5a5a5a;
  margin-top: auto; /* Ensures the footer is pushed to the bottom */
}
