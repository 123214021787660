/* src/ProjectPage.css */
.project-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.project-images {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  padding: 10px;
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 20px;
}

.project-page h2 {
  font-family: "Playfair Display", serif;
  color: #5a5a5a;
}

.project-image:last-child {
  margin-bottom: 0;
}

.navigation-links {
  margin-top: 20px;
}

.nav-link {
  margin: 0 10px;
  text-decoration: none;
  font-family: "Playfair Display", serif;
  color: #5a5a5a;
}

.nav-link:hover {
  text-decoration: underline;
}
