/* src/styles/ContactPage.css */

.contact {
  font-family: "Roboto", "Helvetica", "Sans-serif";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.contact h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #5a5a5a;
}

.contact p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #5a5a5a;
}

.contact form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.contact label {
  margin-bottom: 10px;
}

.contact input,
.contact textarea {
  width: 100%;
  padding: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Roboto", "Helvetica", "Sans-serif";
  font-size: 1rem;
  color: #495057;
}

.contact textarea {
  height: 170px;
}

.contact button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #5a5a5a;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.contact button:hover {
  background-color: #808080;
}
