/* src/HomePage.css */
.image-grid-container {
  padding: 10px;
}

.slogan {
/*    position: absolute;*/
    bottom: 10px;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 2;
    color: #5a5a5a;
    width: 100%;
    text-align: center;
    font-family: "Playfair Display", serif;
}

.slogan h1 {
    font-size: 2.5rem;
}

.slogan p {
    font-family: "Roboto", "Helvetica", "Sans-serif";
    line-height: 1.5em;
    font-weight: 500;
    font-size: 1.3rem;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px; /* Space between images */
  margin: 0 auto; /* Center the grid */
  max-width: 1000px; /* Maximum width of the grid */
  padding: 5px; /* Padding around the grid */
  margin-top: 35px;
  box-sizing: border-box; /* Ensure padding does not affect the container size */
}

.image-container {
  cursor: pointer;
  padding: 5px; /* Padding inside each image container */
  box-sizing: border-box; /* Ensure padding does not affect the container size */
  background-color: #f0f0f0; /* Background color for better visibility */
  border-radius: 5px; /* Rounded corners for a nicer look */
}

.image-item {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px; /* Match the container's rounded corners */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .slogan h1 {
    font-size: 1.4rem;
  }

  .slogan p {
    line-height: 1.3em;
    font-weight: 300;
    font-size: 1rem;
  }

  .image-grid {
    margin-top: 20px;
  }
}
