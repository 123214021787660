/* src/styles/Header.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px;
  background-color: #d7ded9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%; /* Ensure it does not exceed the viewport width */
  box-sizing: border-box; /* Include padding in width */
  z-index: 1000; /* Ensure it stays above other content */
}

.company-name span {
    font-family: "Roboto", "Helvetica", "Sans-serif";
    font-size: 24px;
    font-weight: bold;
    color: #5a5a5a;
    text-decoration: none;
    position: relative;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo img {
  height: 50px;
}

.no-underline {
  text-decoration: none;
}

.menu-toggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.menu-close {
  display: none;
}

.nav {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav ul li {
  display: inline;
}

.nav ul li a {
  text-decoration: none;
  color: #5a5a5a;
  font-size: 16px;
}

.nav ul li a:hover {
  color: #000;
}


/* Mobile Styles */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .company-name {
/*    order: 1;*/
    text-align: center;
    margin-top: 10px;
  }

  .nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 120px;
    background-color: #d7ded9;
    transform: translateX(100%);
    flex-direction: column;
    justify-content: top;
    align-items: center;
  }

  .company-name span {
    font-size: 1rem;
    font-weight: 400;
    padding-top: 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .nav.open {
    transform: translateX(0);
  }

  .menu-close {
    display: block;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .nav ul {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }

  .nav ul li {
    text-align: left;
  }

  .nav ul li a {
    display: block;
    padding: 10px 0;
  }

  .nav ul li:first-child {
    margin-top: 50px;
  }
}

