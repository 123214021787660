/* src/styles/NotFoundPage.css */

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  text-align: center;
}

.not-found h1 {
  font-size: 72px;
  margin-bottom: 20px;
}

.not-found p {
  font-size: 24px;
  margin-bottom: 20px;
}

.not-found a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

.not-found a:hover {
  text-decoration: underline;
}
